import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const SVG = ({ className, width, height, src, ...otherProps }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    if (!isLoaded) setIsLoaded(true);
  };

  return (
    <img
      className={classNames(
        'transition-opacity opacity-0 ease-linear duration-500',
        isLoaded && 'opacity-100',
        className
      )}
      width={width}
      height={height}
      src={src}
      loading="lazy"
      alt=""
      ref={(img) => {
        if (img?.complete && !isLoaded) setIsLoaded(true);
      }}
      aria-hidden
      onLoad={handleLoad}
      {...otherProps}
    />
  );
};

SVG.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
};

SVG.defaultProps = {
  className: null,
};

export default SVG;
