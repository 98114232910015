import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import React from 'react';

import SVG from './svg';
import SVGAndGatsbyImage from './svg-and-gatsby-image';

const ImageComponent = ({ imageSrc, gatsbyImage, ...otherProps }) =>
  imageSrc && !isEmpty(gatsbyImage) ? (
    <SVGAndGatsbyImage imageSrc={imageSrc} gatsbyImage={gatsbyImage} {...otherProps} />
  ) : (
    <SVG src={imageSrc} {...otherProps} />
  );

ImageComponent.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  gatsbyImage: PropTypes.object,
};

ImageComponent.defaultProps = {
  gatsbyImage: null,
};

export default ImageComponent;
